import { Box, TableContainer, Typography } from '@mui/material'
import { ActionsButton, BoxContainer, FieldsFilter, SelectInput, TitleFirst } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { EMPLOYEE_ROLE_TYPE, isEmpty, JOB_STATUS_FILTERS, pcViewWOStatusOptions, ROWS_PER_PAGE_EXTRA_EXTENDED, STATUS } from 'src/helpers'
import { icons } from '../../../../assets'
import TopInfo from './PCTopInfo'

import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Range } from 'react-date-range'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'
import { companiesActions, filtersActions, pcViewActions, territoriesActions, territoryActions } from 'src/ducks/actions'
import { getProjectCoordinatorFilter } from 'src/ducks/filters/selector'
import { SearchParams } from 'src/ducks/searches/types'
import { getAllCompaniesForMultipleFieldWithoutAllOption, getEmployee, getPCCSV, getPCWorkOrders, getTerritoriesSelector, getTerritoryManagers, getTerritoryManagersUsers, getTerritorySuccessManagers, getTerritorySuccessManagersUsers } from 'src/ducks/selectors'
import { useIsXlScreen } from 'src/hooks'
import headers from './headers'
import styles from './styles.module.scss'

const ordersOptions = [
  { key: 'all', label: 'All Orders' },
  { key: 'broker', label: 'Broker Orders' },
  { key: 'institutional', label: 'Institutional Orders' },
  { key: 'consumer', label: 'Consumer Orders' },
]

interface SearchInputs {
  range: Range
}

const states = [
  JOB_STATUS_FILTERS.ALL,
  JOB_STATUS_FILTERS.ACTIVE,
  JOB_STATUS_FILTERS.SCHEDULING,
  JOB_STATUS_FILTERS.DEPOSIT_PENDING,
  JOB_STATUS_FILTERS.DISPATCH_PENDING,
  JOB_STATUS_FILTERS.DISPATCHED,
  JOB_STATUS_FILTERS.NEEDS_REVIEW,
  JOB_STATUS_FILTERS.COMPLETE_NOT_INVOICED,
  JOB_STATUS_FILTERS.COMPLETE_INVOICED,
  JOB_STATUS_FILTERS.COMPLETE_PARTIALLY_PAID,
  JOB_STATUS_FILTERS.COMPLETE_IN_COLLECTION,
  JOB_STATUS_FILTERS.CLOSED,
]


export const PCView = () => {
  const { id } = useParams()

  const dispatch = useDispatch()

  const workOrders = useSelector(getPCWorkOrders())

  const workOrdersAddedPadding = workOrders?.gridData?.map((wo) => {
    return {
      ...wo,
      padding: '0px 8px !important'
    }
  }
  )

  const territoriesOptions = useSelector(getTerritoriesSelector)

  const pcFilters = useSelector(getProjectCoordinatorFilter())

  const [woStatusOption, setWoStatusOption] = useState<SelectInputOption | null>(null)
  const [rangeSelected, setRangeSelected] = useState<Range | null>(null)
  const [jobNumber, setJobNumber] = useState(pcFilters.jobNumber || '')
  const [loading, setLoading] = useState<boolean>(false)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [territory, setTerritory] = useState(
    pcFilters.territory || territoriesOptions[0]
  )
  const [tableHeaders, setTableHeaders] = useState(headers)

  const [statusSelected, setStatusSelected] = useState(
    pcFilters.statusSelected ?? 1
  )
  const affiliatesStages = useSelector(
    getAllCompaniesForMultipleFieldWithoutAllOption
  )
  const [selectedAffiliates, setSelectedAffiliates] = useState(
    pcFilters.affiliates ?? affiliatesStages
  )
  const [loadingCSV, setLoadingCSV] = useState(false)
  const downloadRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const [filters, setFilters] = useState(false)
  const [orders, setOrders] = useState(pcFilters.orders ?? ordersOptions[0])
  const [xlScreen] = useIsXlScreen()

  const [search, setSearch] = useState<SearchInputs>({
    range: !isEmpty(pcFilters.approvalDate)
      ? {
        startDate: new Date(pcFilters.approvalDate.startDate),
        endDate: new Date(pcFilters.approvalDate.endDate),
      }
      : {},
  })


  const minWidthSel = xlScreen ? '140px' : '100px'
  const affiliateSelectorWidth = xlScreen ? '156px' : 'auto'

  const territorySuccessManagersForDropdown = useSelector(
    getTerritorySuccessManagersUsers
  )
  const territoryManagersForDropdown = useSelector(getTerritoryManagersUsers)
  const territorySuccessManagers = useSelector(getTerritorySuccessManagers)
  const territoryManagers = useSelector(getTerritoryManagers)
  const currentUser = useSelector(getEmployee)
  const isManager = currentUser.roles.find(
    (role) => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER
  )

  const indexOfCSM = territorySuccessManagersForDropdown.findIndex(
    (user) => user.key === currentUser.id
  )
  const [cmsUsers, setCmsUsers] = useState(
    pcFilters.csmUser ??
    (isManager
      ? territorySuccessManagersForDropdown[indexOfCSM]
      : territorySuccessManagersForDropdown[0])
  )
  const [tmUsers, setTmUsers] = useState(
    pcFilters.tmUser ?? territoryManagersForDropdown[0]
  );

  const pcCSV = useSelector(getPCCSV)


  const onTableFilterChange = (index: number): void => {
    setTableHeaders(
      tableHeaders.map((item, indx) => {
        if (indx === index) {
          return { ...item, hide: !item.hide }
        }
        return item
      })
    )
  }

  const fetchServices = useCallback(() => {
    // setTableLoading(true)
    dispatch(
      territoriesActions.fetchTerritories((_succ: boolean) => {
        // setPageLoading(false)
      })
    )
    dispatch(
      territoryActions.fetchTerritoryManagers(
        `${EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER}`,
        (_succ: boolean) => {
          // setPageLoading(false)
        }
      )
    )
    dispatch(
      territoryActions.fetchTerritoryManagers(
        `${EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER}`,
        (_succ: boolean) => {
          // setPageLoading(false)
        }
      )
    )
    dispatch(
      companiesActions.fetchCompanies({}, (_succ: boolean) => {
        // setPageLoading(false)
      })
    )
    // dispatch(jobsActions.fetchJobsCounts({ searchParams: {} }, (_succ: boolean) => { setPageLoading(false) }))
  }, [])

  useEffect(() => {
    dispatch(
      pcViewActions.fetchPCPortfolioCount({ id: cmsUsers.key || id || '' })
    )
    dispatch(
      pcViewActions.fetchPCDetails({ id: cmsUsers.key || id || '' })
    )
    dispatch(
      pcViewActions.fetchPCFinancials({ id: cmsUsers.key || id || '' })
    )
    dispatch(
      pcViewActions.fetchPCFinancialsJobs({ id: cmsUsers.key || id || '' })
    )
    fetchServices()
  }, [cmsUsers])

  useEffect(() => {
    setTmUsers(pcFilters.tmUser ?? territoryManagersForDropdown[0])
  }, [territoryManagers])


  const handleWorkOrderStatusOptionChange = (newValue: SelectInputOption) => {
    setWoStatusOption(newValue)
  }

  const handleDateRangeSelect = (range: Range) => {
    setRangeSelected(range)
  }

  const getParams = () => {
    const searchParams = {} as Partial<SearchParams>

    if (jobNumber !== '') {
      searchParams.search = jobNumber
    }

    if (territory && territory?.label !== 'All Territories') {
      searchParams.territory = territory.key
    }
    const statusLabel = states[statusSelected]

    if (statusLabel !== "All") {
      switch (states[statusSelected]) {
        case JOB_STATUS_FILTERS.ACTIVE:
          searchParams.status = [
            STATUS.SCHEDULING,
            STATUS.DEPOSIT_PENDING,
            STATUS.DISPATCH_PENDING,
            STATUS.DISPATCHED,
            STATUS.REVIEW,
          ].join(',')
          break
        case JOB_STATUS_FILTERS.SCHEDULING:
          searchParams.status = STATUS.SCHEDULING
          break
        case JOB_STATUS_FILTERS.DEPOSIT_PENDING:
          searchParams.status = STATUS.DEPOSIT_PENDING
          break
        case JOB_STATUS_FILTERS.DISPATCH_PENDING:
          searchParams.status = STATUS.DISPATCH_PENDING
          break
        case JOB_STATUS_FILTERS.DISPATCHED:
          searchParams.status = STATUS.DISPATCHED
          break
        case JOB_STATUS_FILTERS.NEEDS_REVIEW:
          searchParams.status = STATUS.REVIEW
          break
        case JOB_STATUS_FILTERS.COMPLETE_NOT_INVOICED:
          searchParams.status = STATUS.COMPLETE
          searchParams.statusComp = STATUS.NOT_INVOICED
          break
        case JOB_STATUS_FILTERS.COMPLETE_INVOICED:
          searchParams.status = STATUS.COMPLETE
          searchParams.statusComp = STATUS.INVOICE_SENT
          break
        case JOB_STATUS_FILTERS.COMPLETE_PARTIALLY_PAID:
          searchParams.status = STATUS.COMPLETE
          searchParams.statusComp = STATUS.PARTIAL_PAYMENT
          break
        case JOB_STATUS_FILTERS.COMPLETE_IN_COLLECTION:
          searchParams.status = STATUS.COMPLETE
          searchParams.statusComp = STATUS.IN_COLLECTION
          break
        case JOB_STATUS_FILTERS.CLOSED:
          searchParams.status = STATUS.CLOSED
          break
      }
    }

    if (cmsUsers?.key) {
      searchParams.successManager = cmsUsers?.key || ''
    }
    if (tmUsers?.key) {
      searchParams.territoryManager = tmUsers?.key || ''
    }

    if (orders?.key !== 'all') {
      searchParams.affiliateType = orders?.key.toUpperCase()
    }

    const resAffiliates = selectedAffiliates
      .filter((aff) => !aff.hide)
      .reduce((acc: any, curr: any) => acc.concat(curr.key), [])
    const queryAffiliates = resAffiliates.join(',')
    if (resAffiliates.length !== 0 && !resAffiliates.includes('all')) {
      searchParams.affiliate = queryAffiliates
    }

    if ('startDate' in search.range && 'endDate' in search.range) {
      searchParams.approvalStartDate = moment(search.range.startDate).format(
        'MM/DD/YYYY'
      )
      searchParams.approvalEndDate = moment(search.range.endDate).format(
        'MM/DD/YYYY'
      )
    }

    return searchParams
  }

  const downloadCSV = (event: any) => {
    const searchParams = getParams()
    setLoadingCSV(true)
    dispatch(
      pcViewActions.downloadCSV(
        {
          id: cmsUsers.key || id || "",
          searchParams,
        },
        (succ: boolean) => {
          setLoadingCSV(false)
          succ && downloadRef?.current?.link?.click()
        }
      )
    )
  }

  const fetchWorkOrders = (start: number = 0, limit: number = 50) => {
    setLoading(true)
    const searchParams = getParams()
    const jobStatus = searchParams.status || null;
    const paymentStatus = searchParams.statusComp || null;

    const workOrderStatus = woStatusOption?.key || null;
    const fromDate = rangeSelected?.startDate ? moment(rangeSelected?.startDate).format('MMDDYYYY') : null;
    const toDate = rangeSelected?.endDate ? moment(rangeSelected?.endDate).format('MMDDYYYY') : null;
    const affiliate = selectedAffiliates.filter(aff => aff.hide === false).map(aff => aff.key).join(',');
    const affiliateType = orders?.key !== "all" ? orders?.key.toUpperCase() || null : null;
    const territorySelected = territory.key !== "all" ? territory?.key || null : null;
    const territoryManager = tmUsers?.key || null;
    const successManager = cmsUsers?.key || null;

    dispatch(
      pcViewActions.fetchPCWorkOrders({ id: cmsUsers.key || id || '', workOrderStatus, paymentStatus, jobStatus, territoryManager, successManager, territory: territorySelected, fromDate, affiliate, affiliateType, toDate, start, limit }, (succ) => {
        setLoading(false)
      })
    )
  }

  const handleCsmUsers = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setCmsUsers(territorySuccessManagersForDropdown[0])
    } else {
      setCmsUsers(newSelected)
    }
    setFilters(true)
  }

  const handleTMUsers = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setTmUsers(territoryManagersForDropdown[0])
    } else {
      setTmUsers(newSelected)
    }
    setFilters(true)
  }

  const handleATerritoryChange = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setTerritory(territoriesOptions[0])
    } else {
      setTerritory(newSelected)
    }
    setFilters(true)
  }

  const handleAffiliatesChange = (index: number, id: string): void => {
    setFilters(true)

    if (index === -1) {
      // unselect all if index is - 1
      setSelectedAffiliates(
        selectedAffiliates.map((aff) => ({ ...aff, hide: true }))
      )
    } else {
      const realIndex = selectedAffiliates.findIndex((aff) => aff.id === id)
      setSelectedAffiliates(
        selectedAffiliates.map((aff, i) => {
          if (i === realIndex) aff.hide = !aff.hide
          return aff
        })
      )
    }
  }

  const handleChangeStatus = (newOption: { key: string | number }) => {
    setStatusSelected(+newOption.key)
    setFilters(true)
  }

  const handleChangeOptions = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setOrders(ordersOptions[0])
    } else {
      setOrders(newSelected)
    }
    setFilters(true)
  }

  const handleChangeDate = (rangeSelected: Range) => {
    setSearch({ ...search, range: rangeSelected })
    setFilters(true)
  }

  const handleCollapse = () => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed)
  }


  useEffect(() => {
    dispatch(
      filtersActions.setFilters({
        projectCoordinator: {
          territory: territory,
          affiliates: selectedAffiliates,
          approvalDate: search.range,
          csmUser: cmsUsers,
          tmUser: tmUsers,
          jobNumber: jobNumber,
          orders: orders,
          statusSelected: statusSelected,
        },
      })
    )
    fetchWorkOrders()
  }, [woStatusOption, search, rangeSelected, territory, statusSelected, selectedAffiliates, cmsUsers, tmUsers, orders])

  return (
    <Box display="flex" flexDirection="column" style={{ gap: '8px' }}>
      <Box
        display="flex"
        gap={1}
        justifyContent="space-between"
        className={styles.filters}
      >
        <Box onClick={handleCollapse} className={`${styles.collapseButton} ${isCollapsed ? styles.collapseButtonCollapsed : styles.collapseButtonNotCollapsed}`}>
          <icons.ChevronRight style={{ color: "white" }} />
        </Box>
        <SelectInput
          label="Territory:"
          labelVariant='h6Bold'
          onChange={handleATerritoryChange}
          options={territoriesOptions}
          value={territory}
          sx={{ minWidth: minWidthSel, flex: '1' }}
        />

        <SelectInput
          label="Job Status:"
          labelVariant='h6Bold'
          onChange={handleChangeStatus}
          options={states.map((state, idx) => {
            return {
              key: '' + idx,
              label: state,
            }
          })}
          value={states[statusSelected]}
          sx={{ minWidth: minWidthSel, flex: '1' }}
        />

        <FieldsFilter
          flexColumn
          handleChange={handleAffiliatesChange}
          inputText="All Affiliates:"
          popupLabel=""
          options={selectedAffiliates}
          renderAsInput
          contentAlign="left"
          label="Affiliate:"
          variant="body1"
          sx={{ width: affiliateSelectorWidth, flex: '1', maxWidth: "150px" }}
          searchable={true}
          popoverSx={{ width: '378px !important' }}
          searchFieldPlaceholder="Enter Affiliate name"
          allSelection={false}
        />

        <SelectInput
          label="TM:"
          labelVariant='h6Bold'
          onChange={handleTMUsers}
          options={territoryManagersForDropdown}
          value={
            tmUsers === undefined
              ? territoryManagersForDropdown.length > 0
                ? `${territoryManagersForDropdown[1].firstName} ${territoryManagersForDropdown[1].lastName}`
                : 'Fetching territory managers...'
              : tmUsers
          }
          sx={{ minWidth: minWidthSel, flex: '1' }}
          freeSolo={false}
        />

        <SelectInput
          label="CSM User:"
          labelVariant='h6Bold'
          onChange={handleCsmUsers}
          options={territorySuccessManagersForDropdown}
          value={
            cmsUsers === undefined
              ? territorySuccessManagers.length > 0
                ? `${territorySuccessManagers[1].firstName} ${territorySuccessManagers[1].lastName}`
                : 'Fetching success managers...'
              : cmsUsers
          }
          sx={{ minWidth: minWidthSel, flex: '1' }}
          freeSolo={false}
        />
        <SelectInput
          label="Orders:"
          labelVariant='h6Bold'
          onChange={handleChangeOptions}
          options={ordersOptions}
          value={orders}
          sx={{ minWidth: minWidthSel, flex: '1' }}
        />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          minWidth={xlScreen ? '230px' : '170px'}
          sx={{ flex: '1' }}
          maxWidth={xlScreen ? '250px' : '180px'}
        >
          <DatePicker
            onChange={handleDateRangeSelect}
            value={{
              startDate: rangeSelected?.startDate ? new Date(rangeSelected?.startDate) : undefined,
              endDate: rangeSelected?.endDate ? new Date(rangeSelected?.endDate) : undefined
            }}
            size='small'
            label='Date Range'
            allowRange
            align='right'
            labelVariant="h6Bold"
            placeholder="mm/dd/yy - mm/dd/yy"
            quickOptions={['This Year', 'Last Month', 'Last Quarter', 'Last Year']}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth={xlScreen ? 'inherit' : '128px'}
          minWidth="128px"
          className={styles.filters_actions}
        >
          <FieldsFilter
            options={tableHeaders}
            handleChange={onTableFilterChange}
            maxWidth="400px"
            sx={{ height: '56px', minWidth: '56px !important' }}
          />
          <ActionsButton
            loading={loadingCSV}
            icon={<icons.CloudDownload color="disabled" />}
            iconPosition="end"
            onClick={downloadCSV}
            actionStyles={styles.downloadButtonAction}
            sx={{
              minWidth: '56px!important',
              minHeight: '56px',
            }}
          />
          <ExportCSV
            innerRef={downloadRef}
            headers={pcCSV
              ?.slice(0, pcCSV?.search('\n'))
              ?.split(',')
              ?.map((h: any) => h) as any[]}
            data={pcCSV?.slice(pcCSV?.search('\n') + 1) as string}
            fileName={'pc-work-orders.csv'}
            label=""
          />
        </Box>
      </Box>
      <Box className={`${styles.topInfo} ${isCollapsed ? styles.topInfoCollapsed : styles.topInfoNotCollapsed} `}>
        <TopInfo loading={false}>
          <BoxContainer
            sx={{ height: '190px', justifyContent: 'flex-start', padding: "14px 12px" }}
            title={
              <Box display="flex" alignItems="center">
                <TitleFirst
                  alignItems="center"
                  black="Work Order Filter"
                  blackVariant="h5"
                  lineHeight="24px"
                />
              </Box>
            }
          // loading={loading}
          >
            <Box>

              <Box width="264px">
                <SelectInput
                  label="Work Order Status"
                  labelVariant='h6'
                  placeholder='Work Order Status'
                  options={pcViewWOStatusOptions}
                  onChange={handleWorkOrderStatusOptionChange}
                />
              </Box>
            </Box>
          </BoxContainer>
        </TopInfo>
      </Box>
      <TableContainer className={styles.TableScrollBar} style={{ maxHeight: isCollapsed ? "76vh" : "49vh" }}>
        <Table
          headers={tableHeaders}
          defaultOrder="desc"
          sortable
          hasPagination
          rowsPerPageDefault={100}
          rowsPerPageOptions={ROWS_PER_PAGE_EXTRA_EXTENDED}
          stickyPagination
          totalItems={((workOrders?.size || 0) * (workOrders?.numberOfPages || 0)) || 0}
          callToApi={(start, limit) => {
            fetchWorkOrders(start, limit)
          }}
          className={styles.workOrdersTable}
          hasScroll
          highlightedRows
          loading={loading}
          emptyState={<Box className={styles.emptyState}>
            <Typography variant="h6" color="textSecondary">No work orders found</Typography>
          </Box>}
          stickyHeader
        >
          {workOrdersAddedPadding || []}
        </Table>
      </TableContainer>
    </Box>
  )
}
