import { FC } from 'react'
import { BottomMarks, errorImg0, errorImg01, errorImg02, errorImg03, errorImg04, errorImg05, errorImg06, errorImg07, errorImg08, errorImg09, errorImg10, errorImg11, errorImg12, errorImg13, errorImg14 } from 'src/assets'
import useStyles from './styles'

const ErrorRandomImage: FC = () => {
  const classes = useStyles()

  const images = [errorImg0,
    errorImg01,
    errorImg02,
    errorImg03,
    errorImg04,
  ]

  const getRandomNum = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min)
  }

  const renderRandomImage = () => {
    const index = getRandomNum(0, images.length)
    return (
      <div className={classes.root}>
        <img alt='' className={classes.image} src={images[index]} />
        <BottomMarks className={classes.bottomMarks} />
      </div>
    )
  }

  return renderRandomImage()
}

export default ErrorRandomImage
