import { FC } from 'react'
import { BottomMarks, image0, image1, image2, image3, image4 } from '../../../../../assets'
import useStyles from './styles'
import { useClassName } from '../../../../../hooks'
import { Box } from '../../../'

const LoginRandomAnimation: FC = () => {
  const classes = useStyles()
  const className = useClassName()

  const images = [
    {
      uri: image0,
      text: (
        <p className={className(classes.textBase, classes.nouvelle)}>
          Renovate your <b className={classes.moranga}>house</b>
          <br />
          into a <b className={classes.moranga}>home</b>
        </p>
      )
    },
    {
      uri: image1,
      text: (
        <p className={className(classes.textBase, classes.nouvelle)}>
          Sell your home
          <br />
          without <b className={classes.moranga}>stress</b>
        </p>
      )
    },
    {
      uri: image2,
      text: (
        <p className={className(classes.textBase, classes.nouvelle)}>
          Move into a<br />
          <b className={classes.moranga}>healthy home</b>
        </p>
      )
    },
    {
      uri: image3,
      text: (
        <p className={className(classes.textBase, classes.nouvelle)}>
          Built your Realtors - save time,
          <br />
          <b className={classes.moranga}>everytime</b>
        </p>
      )
    },
    {
      uri: image4,
      text: (
        <p className={className(classes.textBase, classes.nouvelle)}>
          Home lifecycle management
          <br />
          <b className={classes.moranga}>made easy</b>
        </p>
      )
    }
  ]

  const getRandomNum = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min)
  }

  const renderRandomImage = () => {
    const index = getRandomNum(0, images.length)
    return (
      <div className={classes.root}>
        <img alt='' className={classes.image} src={images[index].uri} />
        <Box className={classes.textBase}>{images[index].text}</Box>
        <BottomMarks className={classes.bottomMarks} />
      </div>
    )
  }

  return renderRandomImage()
}

export default LoginRandomAnimation
