import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid /* , LoginRandomAnimation  */ } from '../../../UI'
import useStyles from './styles'
import { TemplateProps } from '../types'
import { logoAlt, loginTexture } from '../../../../assets'
import { PublicLoader } from '../..'
import { getConfigValue } from '../../../../ducks/selectors'

const Template: FC<TemplateProps> = ({ children }) => {
  const classes = useStyles()
  const publicLoading = useSelector(getConfigValue('publicLoading'))

  return (
    <PublicLoader loading={publicLoading}>
      {/* <img src={logoAlt} alt='' className={classes.logoAlt} /> */}
      <Grid container className={classes.root}>
        <Grid
          display={{ xs: 'none', lg: 'grid' }}
          item
          xs={5}
          className={classes.logoAltItem}
        >
          <img src={logoAlt} alt='' className={classes.logoAlt} />
        </Grid>
        <Grid item xs={12} lg={7} className={classes.center}>
          <Box className={classes.childrenBox}>{children}</Box>
        </Grid>
      </Grid>
      <img src={loginTexture} alt='' className={classes.loginTexture} />
    </PublicLoader>
  )
}

export default Template
