import { Box, Tooltip, Typography } from '@mui/material'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'

import styles from './styles.module.scss'
import { InsertLink, NorthEast } from '@mui/icons-material'
import ShowMore from 'src/components/UI/CustomUI/atoms/ShowMore'
import moment from 'moment'
import { formatTimestamp } from 'src/helpers'

export const removeProtocol = (url: string): string => {
  if (!url) {
    return ''
  }

  return url.replace(/(^\w+:|^)\/\//, '')
}

const headers: HeaderType[] = [
  {
    id: 'workOrderTitle',
    label: 'Work Order Title',
    custom: true,
    align: 'left' as AlignType,
    padding: "0px 8px",
    minWidth: 150,
    highlightRows: true,
    stickyLeft: "0px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <a style={{ cursor: 'pointer' }} href={`/work-orders/${row.id}`}>
            <Typography className={styles.Companies__companyName} style={{ color: 'var(--blue700)' }}>
              {`${row.workOrderTitle || "-"}`}
            </Typography>
          </a>
        </Box>
      )
    },
  },
  {
    id: 'orderPublicId',
    label: 'Order Public ID',
    custom: true,
    align: 'left' as AlignType,
    minWidth: 105,
    padding: "0px 8px",
    highlightRows: true,
    // stickyLeft: "0px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <a style={{ cursor: 'pointer' }} href={`/work-orders/${row.id}`}>
            <Typography className={styles.Companies__companyName} style={{ color: 'var(--blue700)' }}>
              {row.orderPublicId || "-"}
            </Typography>
          </a>
          {/* <Typography className={styles.Companies__companyName}>
            {row.orderPublicId || "-"}
          </Typography> */}
        </Box>
      )
    },
  },
  // {
  //   id: 'estimatePublicId',
  //   label: 'Estimate Public ID',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   highlightRows: true,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.estimatePublicId}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'propertyAddress',
    label: 'Property Address',
    custom: true,
    stickyLeft: "150px",
    align: 'center' as AlignType,
    padding: "0px 8px",
    minWidth: 250,
    highlightRows: true,
    Element: (row: any): JSX.Element => {

      const isColoredRed = row.workOrderQcNeeded === "NEEDS_ATTENTION" || row.jobStartCountdown?.includes("-")
      const isColoredOrange = row.customerSatisfactionReceived === "NEEDS_ATTENTION" || row.markJobComplete === "NEEDS_ATTENTION" || row.payVendor === "NEEDS_ATTENTION" || row.pendingProAssignment || (row.jobStartCountdown?.includes("+") && row.jobStartCountdown?.includes("hs") && row.jobStartCountdown?.replace(/\D/g, '') < 24);
      const textColor = isColoredRed ? "rgb(224, 31, 31)" : isColoredOrange ? "#EBA900" : "unset"
      return (
        <Box style={{ padding: '2px 10px' }}>
          <Typography className={styles.Companies__companyName} color={textColor}>
            {`${row.propertyAddress}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'territory',
    label: 'Territory',
    custom: true,
    align: 'center' as AlignType,
    padding: "0px 8px",
    minWidth: 150,
    highlightRows: true,
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '2px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {`${row.territoryName || "-"}`}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'cityStateZip',
  //   label: 'City, State, Zip',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 180,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.cityStateZip}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'estimatedDateOfCompletion',
    label: 'Estimated Date of Completion',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 125,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.estimatedDateOfCompletion ? moment(row.estimatedDateOfCompletion).format("MM-DD-YY") : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'percWorkOrderComplete',
    label: '% of Work Order Complete',
    custom: true,
    minWidth: 125,
    align: 'center' as AlignType,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.percWorkOrderComplete ? `${row.percWorkOrderComplete}%` : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'totalContract',
    label: 'Approved Total',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 125,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.totalContract || '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'dispatchPending',
    label: 'Dispatch Pending',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 125,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      const hasColor = row.pendingProAssignment;
      const textColor = hasColor ? "#EBA900" : "unset"

      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName} color={textColor}>
            {row.pendingProAssignment ? 'NEEDS ATTENTION' : ''}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'jobStartDate',
    label: "Work Order Scheduled Start Date / Time",
    custom: true,
    align: 'center' as AlignType,
    minWidth: 180,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.jobStartDate ? moment(row.jobStartDate).tz(row.territoryTimezone).format("MM-DD-YY hh:mm A z") : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'acceptedByPro',
    label: 'Accepted By Pro',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.acceptedByPro ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'proCheckIn',
    label: 'Pro Check In Date / Time',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 180,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.proCheckIn}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'jobStartCountdown',
    label: 'Job Start Countdown',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 180,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      const isColoredRed = row.jobStartCountdown?.includes("-")
      const isColoredOrange = row.jobStartCountdown?.includes("+") && row.jobStartCountdown?.includes("hs") && row.jobStartCountdown?.replace(/\D/g, '') < 24;
      const textColor = isColoredRed ? "rgb(224, 31, 31)" : isColoredOrange ? "#EBA900" : "unset"
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName} color={textColor}>
            {row.jobStartCountdown}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'lastCommentDate',
    label: 'Last Comment Date',
    custom: true,
    minWidth: 135,
    padding: "0px 8px",
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.lastCommentDate || "-"}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'lastComment',
    label: 'Last Comment',
    custom: true,
    minWidth: 300,
    align: 'center' as AlignType,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      const linkToRedirect = `/jobs/${row.estimateId}?tab=0`;

      return (
        (row.lastComment && row.lastComment.length > 50) ? <Tooltip
          title={
            <Box style={{ padding: '1px 10px' }}>
              <Typography className={styles.Companies__companyName}>
                {`${row.lastComment || "-"}`}
              </Typography>
            </Box>
          }
          placement='top'
          enterDelay={500}
          leaveDelay={200}
          arrow
        >
          <Box style={{ padding: '1px 10px' }}>
            <a style={{ cursor: 'pointer' }} href={linkToRedirect} target='_blank' rel="noreferrer">
              <Typography className={styles.Companies__companyName} style={{ color: 'var(--blue700)' }}>
                {`${row.lastComment.slice(0, 50) + "..." || "-"}`}
              </Typography>
            </a>
          </Box>
        </Tooltip>
          : <Box style={{ padding: '1px 10px' }}>
            <a style={{ cursor: 'pointer' }} href={linkToRedirect} target='_blank' rel="noreferrer">
              <Typography className={styles.Companies__companyName} style={{ color: 'var(--blue700)' }}>
                {`${row.lastComment || "-"}`}
              </Typography>
            </a>
          </Box>
      )
    },
  },
  {
    id: 'payAtClose',
    label: 'Pay at Close',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.payAtClose ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'closingDate',
    label: 'Listing Closing Date',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 110,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.closingDate ? moment(row.closingDate).tz(row.territoryTimezone).format("MM-DD-YY hh:mm A z") : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'changeOrderPendingApproval',
    label: 'Change Order Pending Approval',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 150,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.changeOrderPendingApproval || "-"}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'dispatchOrder',
  //   label: 'Dispatch Order',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 80,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.dispatchOrder ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'receiveVendorResponse',
  //   label: 'Receive Vendor Response',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.receiveVendorResponse ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'workOrderQcNeeded',
    label: 'Work Order QC Needed',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 120,
    padding: "0px 8px",
    // bgColor: (row: any): string => {
    //   const isColored = row.workOrderQcNeeded === "NEEDS_ATTENTION"
    //   return isColored ? "rgb(224, 31, 31)" : "unset"
    // },
    Element: (row: any): JSX.Element => {
      const isColored = row.workOrderQcNeeded === "NEEDS_ATTENTION"
      const textColor = isColored ? "rgb(224, 31, 31)" : "unset"
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName} color={textColor}>
            {row.workOrderQcNeeded}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'clientReview',
    label: 'Customer Satisfaction Received',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    // bgColor: (row: any): string => {
    //   const isColored = row.customerSatisfactionReceived === "NEEDS_ATTENTION"
    //   return isColored ? "#EBA900" : "unset"
    // },
    Element: (row: any): JSX.Element => {
      const isColored = row.customerSatisfactionReceived === "NEEDS_ATTENTION"
      const textColor = isColored ? "#EBA900" : "unset"

      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName} color={textColor}>
            {row.customerSatisfactionReceived}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'payVendor',
    label: 'Pay Vendor',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      const isColored = row.payVendor === "NEEDS_ATTENTION";
      const textColor = isColored ? "#EBA900" : "unset"
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName} color={textColor}>
            {row.payVendor}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'markJobComplete',
    label: 'Mark WO Complete',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    // bgColor: (row: any): string => {
    //   const isColored = row.markJobComplete === "NEEDS_ATTENTION"
    //   const isWithinADay = moment(row.mjcStartedOn)?.diff(moment(), 'hours') < 24
    //   const isPastADay = moment(row.mjcStartedOn)?.diff(moment(), 'hours') > 24

    //   return isColored && isPastADay ? "rgb(224, 31, 31)" : isColored && isWithinADay ? "#EBA900" : "unset";
    // },
    Element: (row: any): JSX.Element => {
      const isColored = row.markJobComplete === "NEEDS_ATTENTION"
      const isWithinADay = moment(row.mjcStartedOn)?.diff(moment(), 'hours') < 24
      const isPastADay = moment(row.mjcStartedOn)?.diff(moment(), 'hours') > 24

      const textColor = !isColored ? "unset" : isColored && isPastADay ? "rgb(224, 31, 31)" : isColored && isWithinADay ? "#EBA900" : "#EBA900";

      const linkToRedirect = `/jobs/${row.estimateId}?tab=0`;
      return (
        <Box style={{ padding: '1px 10px' }}>
          <a target='_blank' rel="noreferrer" style={{ cursor: 'pointer', color: textColor }} href={linkToRedirect}>
            <Typography className={styles.Companies__companyName} color={textColor} style={{ textDecoration: 'underLine' }}>
              {row.markJobComplete || ""}
            </Typography>
          </a>
        </Box>
      )
    },
  },
  // {
  //   id: 'pendingSelection',
  //   label: 'Pending Selection',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.pendingSelection}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'pendingQualityControl',
  //   label: 'Pending QC',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.pendingQualityControl}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'payAtClose',
  //   label: 'Pay at Close',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.payAtClose ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'closingDate',
  //   label: 'Closing Date',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.closingDate}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },

  // {
  //   id: 'dateOfOriginalContract',
  //   label: 'Date of Original Contract',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {`${row.dateOfOriginalContract} ${row.proContactPhone}`}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'workItemTotal',
    label: 'Work Item Total',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.workItemTotal}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'notStarted',
    label: 'Not Started',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.notStarted || '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'inProgress',
    label: 'In Progress',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 90,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.inProgress}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'workItemComplete',
    label: 'Work Item QC Pending',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 108,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.workItemComplete}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'workItemAccepted',
    label: 'Work Item Accepted',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 108,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.workItemAccepted}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'customerName',
    label: 'Customer Name',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 150,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.customerName}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'agentName',
    label: 'Agent Name',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 125,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.agentName || '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'pendingInvoice',
    label: 'Pending Invoice',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.pendingInvoice ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'reworkPending',
  //   label: 'Rework Pending',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 80,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.reworkPending ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'pendingCustomerSurvey',
  //   label: 'Pending Customer Survey',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 135,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.pendingCustomerSurvey ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'totalOriginalContractAmount',
  //   label: 'Total Original Contract Amount',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 135,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.totalContractAmount || '-'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'totalChangeOrderAmount',
    label: 'Total Work Order Amount',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 135,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.totalChangeOrderAmount || '-'}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'changeOrderPendingApproval',
  //   label: 'Change Order Pending Approval',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.changeOrderPendingApproval || "-"}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'amountComplete',
    label: 'Amount Complete',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 135,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.amountComplete ? `${row.amountComplete}` : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'contractAmountPending',
    label: 'Amount Remaining',
    custom: true,
    minWidth: 135,
    padding: "0px 8px",
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.contractAmountPending ? `${row.contractAmountPending}` : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'proName',
    label: 'Pro Name',
    custom: true,
    minWidth: 135,
    padding: "0px 8px",
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.proName}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'proPhone',
    label: 'Pro Phone',
    custom: true,
    minWidth: 135,
    padding: "0px 8px",
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.proPhone}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'depositReceived',
  //   label: 'Deposit 20% Amount Received',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.depositReceived ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
]

export default headers
